<!-- =========================================================================================
    File Name: CallToAction.vue
    Description: A call to action component
    Component Name: callToAction
    ----------------------------------------------------------------------------------------
      Author: Noura Toukabri
========================================================================================== -->

<template>
  <div class="call-to-action"
       :title="`${background}`"
       :style="{ backgroundImage: 'url(' + require('@/assets/images/'+ `${background}`) + ')' }">
      <!-- card title -->
      <div class="call_to_action_title">
        <h2 :style="{ color: `${color}` }" >{{ title }} <span class="interro"> ? </span></h2>
        <br>
        <h6  :style="{ color: `${color}` }">{{ subtitle }}</h6>
      </div>
    <br>

      <!-- card actions -->
      <div class="vx-card__actions ">
        <vs-button color="white" type="border" class="border-white"
                   @click="redirectLink(link_url,link_params)">{{link_text}}
        </vs-button>
      </div>
    </div>
</template>

<script>
import { colors } from '../../../themeConfig';
import router from '@/router';

export default {
  name: 'callToAction',
  props: {
    color: String,
    icone: String,
    title: String,
    subtitle: String,
    link_url: String,
    link_text: String,
    link_params: String,
    background: String,
  },
  data() {
    return {
      colors,
      isContentCollapsed: false,
      showCode: false,
      maxHeight: null,
      cardMaxHeight: null,
      codeContainerMaxHeight: '0px',
      tempHidden: false,
    };
  },
  methods: {
    redirectLink(link, params) {
      router.push({
        name: link, query: JSON.parse(params),
      });
    },
  },
};
</script>

<style>
  .border-white:hover {
    border-color: #ed1851 !important;
    color:  #ed1851  !important;
    background: transparent !important;
  }
.call-to-action {
  background-size: cover;
  background-position: right;
  padding: 50px;
  width: 100%;
}

  .call_to_action_title h2 {font-size: 25px;}
  .call_to_action_title h6{font-size: 18px; font-weight: 300;}

</style>
